import { EmbeddingService } from '@accredible-frontend-v2/embedding';
import { environment } from '@accredible-frontend-v2/envs';
import {
  GainsightIdentifyPayload,
  identifyGainsightUser,
} from '@accredible-frontend-v2/utils/gainsight-px';
import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { DashboardIssuer } from '../shared/models/department-details';
import { WebSocketProgressChannelService } from '../shared/services/ws-progress-channel/ws-progress-channel.service';
import { DashboardStoreActions } from './stores/dashboard.actions';
import { DashboardState } from './stores/dashboard.reducer';
import { selectIssuer } from './stores/dashboard.selectors';

@UntilDestroy()
@Component({
  selector: 'db-root',
  templateUrl: './dashboard.container.html',
  styleUrls: ['./dashboard.container.scss'],
})
export class DashboardContainer implements OnInit {
  issuer$: Observable<DashboardIssuer> = this._dashboardStore.select(selectIssuer);
  isEmbeddedRoute: boolean;
  readonly version = environment.version;

  constructor(
    private readonly _dashboardStore: Store<DashboardState>,
    private readonly _embedding: EmbeddingService,
    private readonly _router: Router,
    private readonly _webSocketProgressChannel: WebSocketProgressChannelService,
  ) {}

  ngOnInit(): void {
    this._embedding.v1DataLoaded$
      .pipe(
        untilDestroyed(this),
        first((dataLoaded) => dataLoaded),
      )
      .subscribe(() => {
        // We need this to check if the user is logged in, because if he isn't
        // and this call runs, we get the "not logged in" dialog
        if (this._embedding.departmentId) {
          this._dashboardStore.dispatch(DashboardStoreActions.loadFeatureFlags());
          this._dashboardStore.dispatch(DashboardStoreActions.loadIssuer());
          this._dashboardStore.dispatch(DashboardStoreActions.loadDepartments());
          this._webSocketProgressChannel.connect();
        }
      });

    // TODO V1(Matt): We can remove this logic once v1 is deprecated
    this._router.events
      .pipe(
        untilDestroyed(this),
        filter((event: Event | RouterEvent) => event instanceof NavigationEnd),
      )
      .subscribe((event: NavigationEnd) => {
        this.isEmbeddedRoute = this._isEmbedRoute(event.url);
      });

    this._gainsightIdentify();
  }

  private _gainsightIdentify(): void {
    this.issuer$
      .pipe(
        untilDestroyed(this),
        first((issuer) => !!issuer?.user?.id),
        tap((issuer) => {
          const { user, department: account } = issuer;

          identifyGainsightUser(environment.dashboardGainsightTagKey, <GainsightIdentifyPayload>{
            user: {
              id: user.id,
              firstName: user.name,
              email: user.email,
            },
            account: {
              id: account?.id,
              name: account?.name,
            },
          });
        }),
      )
      .subscribe();
  }

  // Currently, these routes have both a v1 menu sidebar and v2 interface
  private _isEmbedRoute(url: string): boolean {
    const embedRoutes = [
      { base: '/group/', routes: ['/info-appearance', '/general-settings', '/visibility'] },
      { base: '', routes: ['/email-campaigns'] },
      { base: '', routes: ['/new-email-templates'] },
      { base: '', routes: ['/find-campaigns'] },
    ];

    return embedRoutes.some(({ base, routes }) => {
      // Check if the URL starts with the base path (if any) and includes one of the specific routes
      if (base) {
        return url.startsWith(base) && routes.some((route) => url.includes(route));
      }

      // For routes without a base path, just check if the URL includes the specific route
      return routes.some((route) => url.includes(route));
    });
  }
}
